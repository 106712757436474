import { render, staticRenderFns } from "./FooterCopyrightLinks.vue?vue&type=template&id=933e42de&scoped=true&"
import script from "./FooterCopyrightLinks.vue?vue&type=script&lang=ts&"
export * from "./FooterCopyrightLinks.vue?vue&type=script&lang=ts&"
import style0 from "./FooterCopyrightLinks.vue?vue&type=style&index=0&id=933e42de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "933e42de",
  null
  
)

export default component.exports