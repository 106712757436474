








import { defineComponent } from '@nuxtjs/composition-api';
import DisclaimerLogos from '~/components/atoms/DisclaimerLogos.vue';

export default defineComponent({
  name: 'Disclaimers',
  components: { DisclaimerLogos }
});
