




































import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import getComponentCacheKey from '~/helpers/caching/getComponentCacheKey';
import Copyright from '~/components/molecules/AppFooter/Copyright.vue';
import Disclaimers from '~/components/molecules/AppFooter/Disclaimers.vue';
import Social from '~/components/atoms/SocialLinks.vue';
import FooterLinkColumn from '~/cms/footer/FooterLinkColumn.vue';
import LanguageSelector from '~/cms/footer/LanguageSelector.vue';
import { FooterColumn } from '~/types/cms/Footer/FooterColumn/FooterColumn';
import { instanceOfFooterColumn } from '~/types/cms/Footer/FooterColumn/instanceOfFooterColumn';
import { EXPONDO_LOGO_DARK_PATH } from '~/constants/expondoLogoPath';
import { useI18n } from '~/composables/useI18n';

export default defineComponent({
  name: 'AppFooter',
  components: {
    Disclaimers,
    Copyright,
    Social,
    FooterLinkColumn,
    LanguageSelector
  },
  props: {
    phoneNumber: {
      type: Object,
      default: () => ({})
    },
    copyrightLinks: {
      type: Object,
      required: true
    },
    columns: {
      type: Array as PropType<Array<FooterColumn>>,
      default() {
        return [];
      },
      validator: (data: FooterColumn[]) => {
        return data.every(column => instanceOfFooterColumn(column));
      }
    },
    componentCaching: {
      type: String,
      default: ''
    },
    topLevelDomain: {
      type: String,
      required: true
    },
    languageAndCountry: {
      type: String,
      required: true
    }
  },
  serverCacheKey(props: {
    phoneNumber: Object,
    copyrightLinks: Object,
    columns: FooterColumn[],
    componentCaching: string,
    topLevelDomain: string,
    languageAndCountry: string
  }) {
    return getComponentCacheKey({
      componentCaching: props.componentCaching,
      topLevelDomain: props.topLevelDomain,
      languageAndCountry: props.languageAndCountry
    });
  },
  setup() {
    const openColumnTitle = ref('');
    const toggleColumnOpen = (title: string) => {
      if (openColumnTitle.value === title) {
        openColumnTitle.value = '';
      } else {
        openColumnTitle.value = title;
      }
    };
    const { countryLocalization } = useI18n();
    return {
      openColumnTitle,
      toggleColumnOpen,
      EXPONDO_LOGO_DARK_PATH,
      countryLocalization
    };
  }
});
