












import { defineComponent } from '@nuxtjs/composition-api';
import FooterCopyrightLinks from '~/cms/footer/FooterCopyrightLinks.vue';

export default defineComponent({
  components: {
    FooterCopyrightLinks
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  setup () {
    const currentYear = new Date().getFullYear();

    return { currentYear };
  }
});
